@import "../../basestyles/essentials.scss";

// Language: scss
// Path: src/pages/ContactPage/contact-page.scss

.contact-page {
  &__container {
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: $dark-secondary-background-color;

    main {
      padding: $spacing-medium $spacing-xlarge;
      color: $dark-primary-light-text-color;

      h1 {
        font-size: 3rem;
        user-select: none;
      }

      h2 {
        font-size: 2rem;
        user-select: none;
      }

      p {
        font-size: 1.5rem;
        margin-bottom: 15vh;

        span {
          display: block;

          &.block {
            margin-right: 24vw;
          }
        }

        .mail-block--highlighted {
          margin: 0 0.8rem;
        }

        .maplink {
          display: block;
          margin: 0.8rem 0;
        }
      }

      .mail {
        margin-left: 1rem;
        font-size: 1.2rem;

        &__icon {
          width: 22px;
          height: 22px;
          background: url("../../assets/graphics/mail-icon.png") center center
            no-repeat;
          background-size: contain;
        }
      }

      .phone {
        font-size: 1.2rem;

        &__icon {
          width: 22px;
          height: 22px;
          background: url("../../assets/graphics/phone-icon.png") center center
            no-repeat;
          background-size: contain;
        }

        &__curtain-raiser {
          font-size: 1rem;
          color: $dark-tertiary-text-color;
        }

        &__number {
        }
      }

      @include respond-below("desktop") {
        padding: $spacing-medium $spacing-xlarge-mobile;
        
        h1 {
          font-size: 3rem;
        }

        p {
          span {
            &.block {
              margin-right: 0;
            }
          }
        }
      }

      @include respond-below("tablet") {
        padding: $spacing-medium-mobile $spacing-large-mobile;

        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 1rem;

          .mail {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__call-to-serve {
    @include striking();
    display: block;
    max-width: 380px;
  }
}
