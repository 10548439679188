@import "../../basestyles/essentials.scss";

footer {
    z-index: 4;
}

.footer {
    position: relative;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: space-between;
    z-index: 5;

    @include respond-below("tablet") {
        height: auto;
    }
    

    &__content {
        color: $primary-text-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacing-xlarge;
        font-size: 14px;
        z-index: 4;

        @include respond-below("desktop") {
            padding: $spacing-xlarge-mobile;
        }

        @include respond-below("tablet") {
            font-size: 12px;
            padding: $page-gutter-top*2 $spacing-large-mobile $page-gutter-bottom;
        }
    }

    &__link {
        &-container {
            max-width: 65vw;
            overflow-wrap: break-word;

            :not(:last-child) {
                &::after {
                    content: "∙";
                    margin: 0 8px;
                }
            }
        }

        color: $primary-text-color;
        
    }

    &__copyright {
        padding-top: $spacing-medium;
        color: $secondary-text-color;
        font-size: 12px;
        
    }

    &__secrettagline {
        font-family: 'Sawarabhi Braille', sans-serif;
        font-size: 24px;

        @include respond-below("tablet") {
            font-size: 0.9rem;
        }
    }

    

}


.dark {

    .footer {

        &__link {
            color: $dark-secondary-text-color;

            &-container {
                line-height: 28px;
            }
        }

        &__copyright {
            color: $dark-tertiary-text-color;
        }

        &__secrettagline {
            color: $dark-primary-light-text-color;

            @include respond-below("tablet") {
                color: $dark-secondary-text-color;
            }
        }
    }
}