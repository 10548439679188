@import "../../basestyles/essentials.scss";

$standard-padding-horizontal: 48px;
$standard-padding-top: 24px;

$mobile-padding-horizontal: 32px;

$bottom-page-gutter: 48px;
$top-page-gutter: 6vh;

$standard-element-spacing: 24px;



.landing-page {
    &__container {
        min-width: 100%;
        min-height: auto;
        background-color: $primary-background-color;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: space-between;

        @include respond-below("tablet") {
            display: grid;
            grid-template-areas: 
                "logo"
                "tagline";

        }

        &__mobile-grid {
            display: none;
            @include respond-below("tablet") {
                display: block;
                position: absolute;
                z-index: 0;
                width: 100%;
                height: 100vh;
                background-image: url("../../assets/graphics/blueprint-grid-mobile.svg");
                background-size:180vh;
                background-position: -40vw center;
                align-content: center;
            }

        }

        &__tagline {
            &-container {
                grid-area: tagline;
                width: 60%;
                height: 100%;
                background: url("../../assets/graphics/blueprint-grid-desktop.svg") no-repeat bottom right;
                background-size: cover;
                display: grid;
                align-content: end;
                
                z-index: 0;

                @include respond-below("tablet") {
                    background: transparent;
                    width: 100%;
                    height: 60%;
                    background: none;
                    align-content: center;
                    padding-top: 18vh;
                }
            }

            font-size: 48px;
            color: $primary-text-color;
            margin-left: $standard-padding-horizontal;
            z-index: 3;

            @include respond-below('desktop') {
                margin-top: $top-page-gutter - 1;
            }

            @include respond-below("tablet") {
                font-size: 36px;
                margin-left: $mobile-padding-horizontal;
            }

            &-dot {
                @keyframes blink {
                    0% {
                        color: inherit;
                        text-shadow: inherit;
                    }
                    10% {
                        color: #F35F5F;
                        text-shadow: 0 0 4px #e0b1b1;
                    }
                    50% {
                        color: inherit;
                        text-shadow: inherit;
                    }
                    
                }

                animation: blink 4s ease-in-out infinite;
            }

        }

        &__productlinks {
            margin-left: $standard-padding-horizontal;
            margin-bottom: $bottom-page-gutter;
            margin-top: $standard-padding-top;
            z-index: 3;

            a {
                color: $primary-link-color;
                margin-right: $standard-element-spacing;

                @include respond-below("tablet") {
                    font-size: 12px;
                    margin-right: $standard-element-spacing * 0.8;
                }
            }

            @include respond-below("tablet") {
                margin-top: 2vh;
                margin-left: $mobile-padding-horizontal;
            }

            
            

        }

        &__logo {
            &-container {
                grid-area: logo;
                width: 38%;
                height: 100%;

                @include respond-below("tablet") {
                    width: 100%;
                    height: auto;
                    grid-row-end: none;
                }
            }

            height: 4vw;
            display: block;
            fill: $light-brand-color;
            background: transparent;
            overflow: visible;

            margin: $top-page-gutter auto 0;

            @include respond-below("desktop") {
                height: 4vw;
            }

            @include respond-below("tablet") {
                width: 15vw;
                height: 15vw;
            }
            
        }
    }

    &__footer {
        position: relative;
        width: 100%;
        height: 20vh;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 3;

        @include respond-below("tablet") {
            height: auto;
        }
        

        &__content {
            color: $primary-text-color;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $standard-padding-horizontal;
            font-size: 14px;
            z-index: 5;

            @include respond-below("tablet") {
                font-size: 12px;
                padding: $top-page-gutter*2 $mobile-padding-horizontal $bottom-page-gutter;
            }
        }

        &__link {
            &-container {
                max-width: 65vw;
                overflow-wrap: break-word;

                :not(:last-child) {
                    &::after {
                        content: "∙";
                        margin: 0 8px;
                    }
                }
            }

            color: $primary-text-color;
            
        }

        &__copyright {
            padding-top: $standard-padding-top;
            color: $secondary-text-color;
            font-size: 10px;
            
        }

        &__secrettagline {
            font-family: 'Sawarabhi Braille', sans-serif;
            font-size: 24px;

            @include respond-below("tablet") {
                font-size: 0.9rem;
            }
        }

        &__siloart {
            width: 41vw;
            height: 41vw;
            position: absolute;
            bottom: 0;
            right: 5%;
            z-index: 4;

            background: url('../../assets/graphics/siloart-hd-short-terminal.png') no-repeat center;
            background-size: cover;

            @include respond-below("desktop") {
                background-image: url("../../assets/graphics/siloart-mobile-long-terminal.png");
                width: 45vw;
                height: 65vh;
                background-position-y: top;
            }

            @include respond-below("tablet-landscape") {
                background-image: url("../../assets/graphics/siloart-mobile-long-terminal.png");
                width: 55vw;
                height: 65vh;
                background-position-y: bottom;
                background-size: contain;
            }

            @include respond-below("tablet") {
                background-image: url("../../assets/graphics/siloart-mobile-long-terminal.png");
                width: 75vw;
                height: 55vh;
                background-position-y: bottom;
                background-size: contain;
            }

        }
    }



    &--dark {
        body, html {
            background-color: $dark-background-color;
        }

        &.landing-page {

            &__container {
                background-color: $dark-background-color;
            }
        }

        .landing-page {
    
            &__content {
                &__mobile-grid {
                    background-image: url("../../assets/graphics/blueprint-grid-fordark-mobile.svg");
                }

                &__tagline {
                    &-container {
                        background-image: url("../../assets/graphics/blueprint-grid-fordark-desktop.svg");

                        @include respond-below("tablet") {
                            background: transparent;
                        }
                    }

                    color: $dark-primary-text-color;

                    
                }
    
                &__productlinks {
                    a {
                        color: $dark-link-color;
                    }
                }
    
                &__logo {
                    fill: $dark-brand-color;

                    path {
                        fill: $dark-brand-color;
                    }
                }
    
                
            }

            &__footer {
                &__content {
                    color: red;
                }

                &__link {
                    color: $dark-secondary-text-color;

                    &-container {
                        line-height: 28px;
                    }
                }

                &__copyright {
                    color: $dark-tertiary-text-color;
                }

                &__secrettagline {
                    color: $dark-secondary-text-color;
                }
            }
            
        }

        
    }
}