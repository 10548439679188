.link {
  cursor: pointer;
  text-decoration: none;

  &.dark {
    color: $dark-link-color;
  }
}

@mixin striking {
  color: $red-for-dark;
  text-decoration: none;
  font-size: 1.2rem;

  @include respond-below("tablet") {
    font-size: 1rem;
  }
}

.braille {
  font-family: "Sawarabhi Braille", serif;
}

.mail-block {
  &--highlighted {
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid $border-color;
    background-color: transparent;
    color: inherit;
    text-decoration: none;

    &.dark {
      border-color: $border-color-darktheme;
      color: $dark-link-color;
    }
  }
}

.highlighted-block {
  height: 2rem;
  display: inline-block;
  margin: 1rem 0;
  padding: 0.3rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid $border-color;
  background-color: transparent;
  color: inherit;
  text-decoration: none;

  &.dark {
    border-color: $border-color-darktheme;
    color: $dark-link-color;
  }

  &.inline-content {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
  }

  .icon--left {
    margin-right: 1.5rem;
    transform: translateX(-0.5rem);
  }

  .icon--right {
    margin-left: 1.5rem;
    transform: translateX(0.5rem);
  }
}
