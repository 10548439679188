$basic-transition: all 0.3s ease;

$basic-element-margin: 25px;

// Layout - Responsiveness

$res-mobile-min: 320px;
$res-tablet-min: 768px;
$res-tablet-ladscape-min: 1024px;
$res-desktop-min: 1200px;
$res-lg-desktop-min: 1920px;

$res-mobile-max: $res-tablet-min - 1px;
$res-tablet-max: $res-desktop-min - 1px;
$res-tablet-ladscape-max: $res-lg-desktop-min - 1px;
$res-desktop-max: $res-lg-desktop-min - 1px;

$breakpoints: (
	mobile: $res-mobile-min,
	tablet: $res-tablet-min,
	tablet-landscape: $res-tablet-ladscape-min,
	desktop: $res-desktop-min,
	desktopHD: $res-lg-desktop-min,
);


// Colors

$primary-background-color: #ffffff;
$light-brand-color: #686868;

$primary-text-color: #767676;
$secondary-text-color: #9a9a9a;

$primary-link-color: #2D6293;

// Dark mode
$dark-brand-color: #BBEF27;
$dark-background-color: #2e2e2e;
$dark-secondary-background-color: #424242;

$dark-primary-text-color: #f8f8f8;
$dark-primary-light-text-color: #dddddd;
$dark-secondary-text-color: #9a9a9a;
$dark-tertiary-text-color: #767676;


$dark-link-color: #80D2F4;
$dark-link-hover-color: #69bee2;

$red-for-dark: #F35F5F;

$border-color: #9a9a9a;
$border-color-darktheme: #686868;


// Spacings
$spacing-small: 12px;
$spacing-medium: 24px;
$spacing-large: 48px;
$spacing-xlarge: 105px;

$spacing-small-mobile: 12px;
$spacing-medium-mobile: 24px;
$spacing-large-mobile: 32px;
$spacing-xlarge-mobile: 75px;

$page-gutter-top: 6vh;
$page-gutter-bottom: $spacing-medium;