@import "../../basestyles/essentials.scss";

.header-generalised {
  &__container {
    width: 100%;
    height: 24vh;
    display: grid;
    align-content: center;
    justify-items: end;

    @include respond-below("tablet-landscape") {
        height: 15vh;
    }

    @include respond-below("tablet") {
      height: 23vh;
      justify-items: center;
    }
  }
}

.logo {
  &--loudc {
    &-container {
      grid-area: logo;
      width: 38%;
      height: 100%;

      @include respond-below("tablet") {
        width: 100%;
        height: auto;
        grid-row-end: none;
      }
    }

    height: 4vw;
    display: block;
    fill: $light-brand-color;
    background: transparent;
    overflow: visible;

    @include respond-below("desktop") {
      height: 4vw;

      &.portrait {
        transform: translateX(-8vw);
      }
    }

    @include respond-below("tablet") {
      width: 15vw;
      height: 15vw;

      &.portrait {
        transform: translateX(0);
      }
    }

    &.dark {
      fill: $dark-brand-color;

      path {
        fill: $dark-brand-color;
      }
    }
  }
}
