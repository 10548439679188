// Base style file
@font-face {
  font-family: 'Clean Regular';
  src: url('/fonts/Noto_Sans/NotoSans-Regular.ttf')
}

@font-face {
  font-family: 'Clean Bold';
  src: url('/fonts/Noto_Sans/NotoSans-Bold.ttf')
}

@font-face {
  font-family: 'Reader Regular';
  src: url('/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Reader Bold';
  src: url('/fonts/Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Round Regular';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf');
}

@font-face {
  font-family: 'Round Bold';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Bold.ttf');
}

@font-face {
  font-family: 'Round Thin';
  src: url('/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Thin.ttf');
}

@font-face {
  font-family: 'Sawarabhi Braille';
  src: url('/fonts/Sawarabi_Mincho/SawarabiMincho-Regular.ttf');
}

// responsiveness settings
@import "./basestyles/essentials.scss";

.loudc-logo-standalone{
  margin: 127px auto;
  width: 80px;
  fill: #dddddd;
  display: block;
}

body, html {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border: none;
  font-family: 'Clean Regular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;

  @include respond-below("desktop") {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.dark {
    background: $dark-secondary-background-color;

    &--very {
      background: $dark-background-color;
    }
  }
}

a {
  text-decoration: none;
}

#root {
  margin: inherit;
  width: inherit;
  height: inherit;
  border: inherit;
}
