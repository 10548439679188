@import "../../basestyles/essentials.scss";


.about-page {

    &__container {
        min-width: 100%;
        min-height: 100%;

        background: $dark-secondary-background-color;


        main {
            padding: $spacing-medium $spacing-xlarge;
            color: $dark-primary-light-text-color;
            user-select: none;

            h1 {
                font-size: 4rem;
                font-family: 'Sawarabhi Braille', sans-serif;
            }

            p {
                font-size: 1.5rem;
                margin-bottom: 15vh;

                span {
                    display: block;
                    margin-right: 24vw;
                    margin-bottom: $spacing-medium;
                }
            }

            @include respond-below("desktop") {
                padding: $spacing-medium $spacing-xlarge-mobile;

                h1 {
                    font-size: 3rem;
                }

                p {
                    span {
                        margin-right: 0;
                        margin-bottom: $spacing-medium-mobile;
                    }
                }
            }

            @include respond-below("tablet") {
                padding: $spacing-medium-mobile $spacing-large-mobile;


                h1 {
                    font-size: 2rem;
                }
                p {
                    font-size: 1rem;
                }
            }

        }
    }

    &__call-to-serve {
        @include striking();
        display: block;
        max-width: 380px;

    }
}